.error-body {
  height: 100vh;
  width: 100vw;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  background-color: rgb(51, 51, 51);
}

.error-body h1 {
  color: rgb(97, 97, 97);
  font-size: 128px;
  margin: 0;
  padding: 0;
}

.error-body h2 {
  font-size: 38px;
}

.back-home {
  border: 1px solid white;
  padding: 18px;
  border-radius: 5px;
  margin: 28px;
  cursor: pointer;
}

.back-home:hover {
  background-color: rgb(81, 81, 81);
}
