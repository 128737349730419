.footerholder {
  text-align: center;
  justify-content: center;
}

.footer {
  color: white;
  font-size: 12px;
  padding: 28px;
}

@media (max-width: 400px) {
  .footer {
    font-size: 9px;
  }
}
