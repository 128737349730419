.shops {
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}

.shops-container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.681);
}

.shops-bg {
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.681);
  z-index: 10;
}

.location {
  display: flex;
  align-items: center;
  height: 280px;
  position: relative;
  top: 80px;
  font-size: 28px;
}

.shop {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.18);
  padding: 28px;
  margin: 28px;
}

.shop:hover {
  background-color: rgba(255, 255, 255, 0.415);
}

.shopName {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shopName img {
  height: 68px;
}

.shop-detail {
  width: 600px;
}

.shop-photo img {
  width: 380px;
}

@media (max-width: 760px) {
  .shop-detail {
    width: 380px;
  }
  .shopName img {
    height: 48px;
  }
}

@media (max-width: 400px) {
  .shop-detail {
    width: 230px;
  }
  .shop-photo img {
    width: 180px;
  }
  .shopName img {
    height: 33px;
  }
  .location {
    height: 230px;
    top: 68px;
    font-size: 18px;
  }
  .shop {
    font-size: 15px;
  }
}
