.brandDetail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  margin: 10px 0;
  width: 100%;
}

.brandDetailTitle {
  font-size: 28px;
  text-align: center;
}

.brandDetailImage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brandDetailImage img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}

.brandDetailDescription {
  white-space: pre-line;
  text-align: justify;
  margin: 0 28px;
}

@media (max-width: 760px) {
  .brandDetailImage img {
    width: 39%;
  }
}
