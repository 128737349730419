.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  color: black;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 10;
  border-radius: 0 0 8px 8px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.istop {
  background-color: #00000000;
  color: white;
}

.nottop {
  background-color: #242424c8;
  box-shadow: 0 2px 8px rgba(63, 63, 63, 0.25);
  color: rgb(255, 255, 255);
  animation: HeaderDrop 500ms ease;
}

.menu {
  display: flex;
  align-items: center;
}

.menu ul {
  list-style-type: none;
}

.menu ul li {
  float: left;
  margin: 10px 18px;
  position: relative;
}

.nav {
  text-decoration: none;
  color: white;
}

.nav::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffd240;
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.nav:hover {
  color: #ffd240;
}

.nav:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  color: #ffd240;
}

.menu ul li a:hover {
  color: #ffd240;
}

.language-block {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 0 18px;
}

.language-block-left {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border: white 1px solid;
  border-right: none;
  border-radius: 5px 0 0 5px;
}

.language-block-right {
  display: flex;
  height: 30px;
  width: 88px;
  justify-content: center;
  align-items: center;
  border: white 1px solid;
  border-radius: 0 5px 5px 0;
  position: relative;
  cursor: pointer;
}

.language-block-right:hover {
  background-color: rgba(128, 128, 128, 0.539);
}

.language-dropbox {
  display: flex;
  width: 88px;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 30px;
}

.language-dropbox div {
  display: flex;
  width: 88px;
  height: 38px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgba(36, 36, 36, 0.717);
}

.language-dropbox div:hover {
  background-color: #4c4c4c82;
}

.activeNav {
  color: #ffd240;
  text-decoration: underline;
}

.activeLanguage {
  color: #ffd240;
  font-weight: 800;
}

.shown {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.logo {
  justify-content: center;
  margin-left: 38px;
}

.logo img {
  height: 5rem;
}

.close-btn {
  color: white;
  position: absolute;
  right: 30px;
  top: 100px;
  cursor: pointer;
}

.hidden-menu {
  width: 100vw;
  height: 100vh;
  background-color: rgba(23, 23, 23, 0.997);
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
}

.hidden-menu ul {
  list-style-type: none;
}

.hidden-menu ul li {
  padding: 28px;
}

.small-menu {
  visibility: hidden;
  margin: 0 px;
  padding: 0;
  cursor: pointer;
}

@keyframes HeaderDrop {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0);
  }
}

@media (max-width: 760px) {
  .small-menu {
    visibility: visible;
  }
  .menu {
    visibility: hidden;
  }
}

@media (max-width: 400px) {
  .small-menu {
    visibility: visible;
  }
  .menu {
    visibility: hidden;
  }
  .language-block-right {
    font-size: 13px;
    width: 68px;
  }
  .language-dropbox div {
    width: 68px;
  }
}
