.container {
  height: 700px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.456);
}

.container-bg {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cover {
  text-align: center;
  border-width: 1px;
  border-color: black;
  color: rgb(255, 255, 255);
}

.page_name {
  padding: 10px;
  font-size: 38px;
  animation: BottomFadeIn 800ms ease;
}

.description {
  padding: 10px;
  font-size: 28px;
  animation: BottomFadeIn 800ms ease;
}

.establish {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  font-size: 22px;
}

.establish p {
  animation: DelayFadeIn 1600ms ease;
  color: #ffd240;
}

.line {
  height: 0;
  width: 180px;
  border-top: #ffd240 1px solid;
  margin: 18px;
  animation: ExtendLeft 1s ease;
}

@keyframes BottomFadeIn {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes DelayFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ExtendLeft {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media (max-width: 760px) {
  .page_name {
    font-size: 31px;
  }
  .establish {
    font-size: 18px;
  }
  .description {
    font-size: 25px;
  }
}

@media (max-width: 400px) {
  .line {
    width: 80px;
  }
  .page_name {
    font-size: 25px;
  }
  .establish {
    font-size: 15px;
  }
  .description {
    font-size: 18px;
  }
}
