.article-container {
  margin: 48px 38px;
}

.article-title {
  margin: 0;
  padding: 0;
  text-align: justify;
}

.article-date {
  font-size: 15px;
}

/* .article-content-container {
  height: 480px;
  overflow-y: scroll;
} */

.article-img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
}

.article-img img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.article-content {
  text-align: justify;
}

@media (max-width: 768px) {
  .article-img img {
    width: 100%;
  }
  .article-title {
    font-size: 18px;
  }
  .aritcle-content {
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .article-container {
    margin: 38px 18px;
  }
  .article-img img {
    width: 100%;
  }
  .article-title {
    font-size: 18px;
  }
  .aritcle-content {
    font-size: 13px;
  }
}
