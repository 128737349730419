.holder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5c440;
  margin: 0;
  padding: 0;
}

.holder-child {
  width: 100%;
}
