.productBody {
  background-color: rgb(31, 31, 31);
  width: 100%;
  overflow: hidden;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.products {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 120px auto;
  flex-wrap: wrap;
}

.productType {
  position: relative;
  height: 380px;
  width: 380px;
  margin: 30px auto;
  border-radius: 28px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.productType:hover {
  filter: brightness(120%);
}

.type {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 68px;
  width: 180px;
  font-size: 48px;
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -34px;
  margin-left: -90px;
  text-shadow: 3px 3px 2px black;
}

.learnMore {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 120px;
  border: 1px solid white;
  color: white;
  border-radius: 3px;
  position: absolute;
  bottom: 18px;
  left: 50%;
  margin-top: -19px;
  margin-left: -60px;
}

.learnMore:hover {
  background-color: white;
  color: black;
  cursor: pointer;
}

.background {
  height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.origin {
  padding: 120px 80px;
}

.country {
  font-size: 48px;
  color: rgb(196, 196, 196);
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 28px;
}

.brands {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.brand {
  padding: 28px;
  cursor: pointer;
}

.brand:hover {
  background-color: rgba(185, 185, 185, 0.778);
  border-radius: 3px;
}

.brandImage {
  height: 80px;
}

.wineLogo {
  transform: translateX(50%);
}

.wineLogo img {
  width: 200px;
  margin-left: -100px;
  margin-bottom: 68px;
}

.wineDescription {
  background-image: url("../assets/ca-pattern.jpeg");
  background-attachment: fixed;
  filter: brightness(120%);
  padding: 22px;
}

.wineDescriptionBorder {
  border: 1px rgb(255, 213, 0) solid;
  margin: 22px 22px;
  padding-right: 100 px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 68px 0;
}

.wineItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.wineItem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 68px;
}

.wineImage {
  width: 280px;
}

.wineTastingNotes {
  color: white;
  padding-right: 100px;
  overflow-wrap: break-word;
  max-width: 380px;
}

.wineTastingNotes h2 {
  font-size: 28px;
}

.wineTastingNotes h5 {
  font-size: 23px;
  margin: 8px 0;
}

.wineTastingNotes p {
  font-size: 16px;
  margin: 4px 0;
}

.leftFirst {
  animation-name: mergeFromLeft;
  animation-duration: 800ms;
}

.rightFirst {
  animation-name: mergeFromRight;
  animation-duration: 800ms;
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1500ms;
}

@keyframes mergeFromLeft {
  from {
    transform: translateX(-300px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes mergeFromRight {
  from {
    transform: translateX(300px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1200px) {
  .productType {
    height: 280px;
    width: 280px;
  }
}

@media (max-width: 900px) {
  .productType {
    height: 200px;
    width: 200px;
  }
  .type {
    font-size: 33px;
  }
  .learnMore {
    visibility: hidden;
  }
}

@media (max-width: 760px) {
  .type {
    font-size: 30px;
  }
  .country {
    font-size: 38px;
  }
  .wineTastingNotes h2 {
    font-size: 23px;
  }

  .wineTastingNotes h5 {
    font-size: 20px;
  }

  .wineTastingNotes p {
    font-size: 13px;
  }
  .learnMore {
    visibility: hidden;
  }
}

@media (max-width: 400px) {
  .productType {
    height: 100px;
    width: 100px;
  }
  .type {
    font-size: 18px;
  }
  .learnMore {
    visibility: hidden;
  }
  .products {
    margin: 80px auto;
  }
  .country {
    font-size: 22px;
  }
  .brandImage {
    height: 48px;
  }
  .brand {
    padding: 5px;
  }

  .wineLogo img {
    width: 100px;
    margin-left: -50px;
    margin-bottom: 28px;
  }
  .wineItem {
    margin-bottom: 28px;
  }
  .wineDescription {
    padding: 18px;
  }

  .wineImage {
    width: 160px;
    padding: 0;
    margin: 0;
  }
  .wineTastingNotes {
    padding-right: 8px;
    overflow-wrap: break-word;
    max-width: 380px;
  }

  .wineTastingNotes h2 {
    font-size: 16px;
  }

  .wineTastingNotes h5 {
    font-size: 15px;
    margin: 3px 0;
  }

  .wineTastingNotes p {
    font-size: 13px;
    margin: 4px 0;
  }
}
