.news {
  padding: 28px 80px;
  position: relative;
}

.news h1 {
  font-size: 38px;
  color: rgb(79, 78, 78);
  margin: 18px 18px;
}

.newsholder {
  width: 100%;
  justify-content: center;
  display: flex;
}

.left-arrow {
  position: absolute;
  color: white;
  text-shadow: 1px 1px 5px black;
  font-size: 68px;
  font-weight: bolder;
  top: 50%;
  transform: "translate(0, -50%)";
  left: 0;
  z-index: 1;
  cursor: pointer;
}

.right-arrow {
  position: absolute;
  color: white;
  text-shadow: 1px 1px 5px black;
  font-size: 68px;
  font-weight: bolder;
  top: 50%;
  transform: "translate(0, -50%)";
  right: 0;
  z-index: 1;
  cursor: pointer;
}

.news-slider {
  display: flex;
  justify-content: left;
  overflow: hidden;
  align-items: center;
  flex-direction: row-reverse;
}

.image-slider {
  position: relative;
  display: flex;
  align-items: center;
}

.slide {
  opacity: 0;
  transition: opacity 500ms linear;
}

.slide.active {
  opacity: 1;
  transition: opacity 500ms linear;
}

.image {
  max-width: 100%;
  max-height: 100%;
}

.slider-dots {
  position: absolute;
  left: 0;
  bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dot {
  height: 18px;
  width: 18px;
  margin: 0 10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.554);
  cursor: pointer;
}

.dot-active {
  height: 18px;
  width: 18px;
  margin: 0 10px;
  border-radius: 10px;
  background-color: #ffd240;
  cursor: pointer;
}

@keyframes slidingLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translate(-300px);
  }
}

@media (max-width: 760px) {
  .news {
    padding: 0;
    margin: 0;
  }
  .news h1 {
    font-size: 28px;
  }
}

@media (max-width: 400px) {
  .news {
    padding: 0;
    margin: 0;
  }
  .news h1 {
    font-size: 18px;
  }
  .slider-dots {
    bottom: 5px;
  }
  .dot {
    height: 13px;
    width: 13px;
    margin: 0 3px;
  }
  .dot-active {
    height: 13px;
    width: 13px;
    margin: 0 3px;
  }
  .left-arrow {
    font-size: 38px;
  }
  .right-arrow {
    font-size: 38px;
  }
}
