.title {
  font-size: 20px;
  padding: 8px;
  text-align: center;
  cursor: pointer;
}

.title:hover {
  text-decoration: underline;
  color: #f5c440;
}

.date {
  font-size: 13px;
  padding: 5px;
  margin-bottom: 10px;
  text-align: center;
}

.article {
  color: white;
  border-radius: 8px;
  background-color: white;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 10px;
  height: 600px;
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.article-header {
  background-color: rgba(0, 0, 0, 0.546);
  width: 100%;
  border-radius: 8px 8px 0 0;
}

@media (max-width: 760px) {
  .article {
    width: 100vw;
    margin: 0;
    border-radius: 0;
  }
  .article-header {
    border-radius: 0;
  }
}

@media (max-width: 400px) {
  .article {
    width: 100vw;
    height: 380px;
    margin: 0;
    border-radius: 0;
  }
  .article-header {
    border-radius: 0;
  }
  .title {
    font-size: 16px;
  }
  .date {
    font-size: 10px;
  }
}
