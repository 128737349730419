.age-bottons {
  display: flex;
  justify-content: space-evenly;
  padding: 18px 0;
}

.ageholder {
  color: black;
  justify-content: center;
  text-align: center;
  padding: 28px;
}

.age-description {
  padding: 18px 0;
  margin: 0;
}

.age-description h2 {
  margin: 0;
  padding: 18px 0;
}

.age-description p {
  padding: 8px;
  margin: 0;
}

.age-warning {
  color: red;
  font-style: italic;
  font-weight: 500;
}

.button {
  width: 120px;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

@media (max-width: 400px) {
  .age-description h2 {
    font-size: 18px;
  }
  .age-description p {
    font-size: 16px;
  }
  .button {
    width: 80px;
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
  .age-warning {
    font-size: 13px;
  }
}
