.about-container {
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.about-bg {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.403);
  padding: 0;
  margin: 0;
}

.about-us {
  display: flex;
  width: 300px;
  justify-content: center;
  font-size: 48px;
  color: white;
  margin: 0px;
}

.about-box {
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(0deg, rgb(0, 0, 0), rgba(0, 0, 0, 0));
  color: white;
  height: 580px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: BottomFadeIn 800ms ease;
  padding: 0;
  margin: 0;
}

.about-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  flex-direction: column;
  text-align: justify;
  margin: 0;
  padding: 0;
}

.contactus {
  color: #f5c440;
}

@keyframes BottomFadeIn {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 400px) {
  .about-content {
    width: 80%;
    margin: 0 18px;
  }
  .about-us {
    width: 100%;
    font-size: 22px;
  }
  .about-description {
    font-size: 15px;
  }
}
