.p-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.p-modal {
  position: fixed;
  top: 10vh;
  max-height: 85vh;
  width: 780px;
  left: calc(50% - 390px);
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 500ms ease-out forwards;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.p-modal-container {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  max-height: 85vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.closeBtn {
  position: fixed;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

@media (max-width: 760px) {
  .p-modal {
    width: 80%;
    left: 10%;
    top: 5vh;
  }
}

@media (max-width: 400px) {
  .p-modal {
    width: 90%;
    left: 5%;
    top: 5vh;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-8rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
