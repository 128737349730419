.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 18vh;
  max-height: 70vh;
  width: 680px;
  left: calc(50% - 340px);
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

@media (max-width: 768px) {
  .modal {
    width: 80%;
    left: 10%;
    top: 15vh;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
